










import { defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'
import MembersGrid from '@/components/members/Grid.vue'
import RegionsContents from '@/components/regions/Contents.vue'

export default defineComponent({
  name: 'region',
  setup(_props, ctx) {
    const { content } = mapGetters(ctx, ['content'])

    return {
      content,
    }
  },
  components: {
    MembersGrid,
    RegionsContents,
  },
})
