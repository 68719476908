
































import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  props: {
    content: Object,
    onChange: {
      type: Function,
      default: (n, v) => {
        console.log(n, v)
      },
    },
  },
  setup(props) {
    const isOpen = ref(false)
    const current = ref<string>(props.content.options[0].label)

    const close = () => {
      isOpen.value = false
    }

    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    const select = (name, value, label) => {
      props.onChange(name, value)
      current.value = label
      close()
    }

    return {
      current,
      close,
      toggle,
      isOpen,
      select,
    }
  },
})
