































































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
} from '@vue/composition-api'
import CardSlider from '@/components/CardSlider.vue'
import MembersGrid from '@/components/members/Grid.vue'
import RegionsSwitcher from '@/components/regions/Switcher.vue'
import { GenericObject } from '@/inc/types'

export default defineComponent({
  props: {
    content: {
      type: Array as PropType<GenericObject[]>,
      default: () => [],
    },
    members: {
      type: Object as PropType<GenericObject[]>,
    },
  },
  setup(props) {
    const lang = ref('')
    const items = computed(() =>
      props.content.filter(f => f.slug === lang.value)
    )

    const switcher = computed(() => {
      // eslint-disable-line
      const options = [] as GenericObject[]

      props.content.forEach(c => {
        options.push({
          label: c.name,
          value: c.slug,
        })
      })

      return {
        options,
      }
    })

    const onChange = (name, value) => {
      lang.value = value
    }

    onMounted(() => {
      lang.value = props.content[0].slug
    })

    return {
      switcher,
      items,
      onChange,
    }
  },
  components: {
    CardSlider,
    MembersGrid,
    RegionsSwitcher,
  },
})
