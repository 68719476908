






import { defineComponent } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'
import RegionsSingle from './regions/Single.vue'

export default defineComponent({
  name: 'regions',
  components: {
    RegionsSingle,
  },
  setup(props, ctx) {
    return {
      ...mapGetters(ctx, ['template']),
    }
  },
})
